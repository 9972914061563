import React, { useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
// import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql} from 'gatsby'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import {useSpring, animated} from 'react-spring'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Card from '../components/SpringCard'
import LinkCard from '../components/LinkCard'
import { MorphLink, MorphContext } from '../components/LinkCardMorph'



import './content.sass'


const memoCheck = (prevProps, nextProps) => {
  return true
}

const Banner = (props) => {
  // console.log("Banner render");
  const {data, fade, slug } = props;
  if (!data || !data.style) return null;
  let image1Src;
  if (data.image1) {
    if (typeof data.image1 === 'string')
      image1Src = data.image1;
    else if ( data.image1.childImageSharp )
      image1Src = data.image1.childImageSharp.fluid.src;
    else if (data.image1.url)
      image1Src = data.image1.url;
  }

  if (data.style === 'featured')
    return (
      <div className="columns">
        <div className="column is-8 featuredBanner">
          <LinkCard img={data.img} bg={data.bg} id={slug}></LinkCard>
        </div>
        <animated.div className="column is-3 is-offset-1 featuredBanner is-hidden-mobile" style={fade}>
            { image1Src ? <img src={image1Src} className="isZoom" style={{objectFit: 'contain', maxHeight:'350px'}}/> : null }
        </animated.div>
      </div>
    )
}

const MemoizedBanner = React.memo( Banner, memoCheck );


const Links = ({previous, next, fade}) => {
  // console.log('Links render');
  if (!previous && !next) return null;
  const links = [previous, next].map( (link, i) => {
    if (!link) return null;
    const isPrev = i===0;
    let data = link.frontmatter;
    data.id = link.fields.slug.split('/')[2];
    data.morphID = 'morph-' + data.id;
    data.image.height = data.imageHeight;

    return (
      <Card key={data.id}>
        <MorphLink
          to={link.fields.slug}
          id={data.id}
          title={data.title}
          duration={2}
          img={data.image}
          bg={data.background}
          isReverse={false}
          arrowDirection= {isPrev ? 'left' : 'right'}
          className={ isPrev ? "is-pulled-left" : "is-pulled-right" }
        />
      </Card>
    );
  })

  return (
    <animated.div className="columns links" style={fade}>
      <div className="column media-row split" style={!previous ? {"flexDirection":'row-reverse'}: null}>
        { links }
      </div>
    </animated.div>
  )
}

// const MemoizedLinks = React.memo( Links, memoCheck );

export const ProjectTemplate = React.memo(({
  content,
  contentComponent,
  banner,
  // description,
  image,
  imageHeight,
  background,
  title,
  helmet,
  location,
  // id,
  next,
  previous,
  transitionStatus, entry, exit, //mount,
  isPreview
}) => {
  const PostContent = contentComponent || Content
  const slug = location ? location.pathname.split('/')[2] : null;
  const isExiting = transitionStatus==='exiting'

  // const hideMainImage = isExiting && exit.state.hideMainImage;
  const showContent = (transitionStatus==='entered');
  const delay = isExiting ? 0 : (entry.state.appearAfter || 0)
  const duration =  isExiting ? 500 : 1000;


  image.height = imageHeight;
  banner.img = image;
  banner.bg = background;

  const contentFade = useSpring(
    {
      config: { duration: duration },
      from: {opacity : 0},
      to: {opacity: showContent ? 1 : 0},
      delay: delay
    }
  );

  // console.log('Project ' + slug + ' render: ' + transitionStatus);

  return (
    <div className="container contentWrap">
      {helmet || ''}
      { banner ? <MemoizedBanner data={banner} fade={contentFade} slug={slug} /> : null }
      <div className="columns">
        <animated.div className="column is-two-thirds" style={contentFade}>
          <h1>
            {title}
          </h1>
          <PostContent className="content" content={content} />
        </animated.div>
      </div>
      { !isPreview && (next || previous) ? <Links previous={previous} next={next} fade={contentFade}/> : null }


    </div>
  )
},  (prevProps, nextProps) => {
  // console.log(prevProps);
  // console.log(nextProps);

  let statusChange = prevProps.transitionStatus === nextProps.transitionStatus
  // if (!statusChange) {
  //   console.log('Status changed from ' + prevProps.transitionStatus + ' to ' + nextProps.transitionStatus);
  // }
  return statusChange
  // return true
});

ProjectTemplate.propTypes = {
  // content: PropTypes.node.isRequired,
  // contentComponent: PropTypes.func,
  description: PropTypes.string,
  banner: PropTypes.shape({
    style: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  title: PropTypes.string,
  helmet: PropTypes.object,
}










const Project = ({ data, location }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark
  const { transitionStatus, entry, mount, exit  } = useTransitionState();
  const { setMorph } = useContext( MorphContext );

  const image = frontmatter.image;
  image.height = frontmatter.imageHeight;

  useEffect(function(){
    setMorph({
      img: image,
      bg: frontmatter.background
    })
    // console.log('Project mount')
  }, []);

  return (
    //<Layout>
      <ProjectTemplate
        helmet={
          <Helmet titleTemplate="%s | Projects">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />

          </Helmet>
        }
        content={post.htmlAst}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        banner={frontmatter.banner}
        image={image}
        imageHeight={frontmatter.imageHeight}
        background={frontmatter.background}
        title={post.frontmatter.title}
        location={location}
        id={post.id}
        next={frontmatter.next}
        previous={frontmatter.previous}
        transitionStatus={transitionStatus}
        entry={entry}
        mount={mount}
        exit={exit}
      />
    //</Layout>
  )
}

Project.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Project

export const pageQuery = graphql`
  query ProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        title
        description
        imageHeight
        image {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        background {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner {
          style
          image1 {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 400, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 400, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        next {
          fields {
            slug
          }
          frontmatter{
            title
            description
            image{
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageHeight
            background{
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            project_id
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter{
            title
            description
            image{
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageHeight
            background{
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            project_id
          }
        }
      }
    }
  }
`
// links {
//   previousLink {
//     label
//     path
//     image {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 92) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
//   nextLink {
//     label,
//     path,
//     image {
//       childImageSharp {
//         fluid(maxWidth: 300, quality: 92) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// }